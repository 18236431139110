import { get } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col, Csku, DoubleArrowIcon, Row } from "@commonsku/styles";
import { createLoadOrder } from "../../../actions/order";
import Footer from "../Footer";
import { setFilter } from "../../../redux/gallery";
import {
  TEMPLATE_SIDEBAR_WIDTH,
} from "../helpers";
import Sidebar from "../Sidebar";
import useContainerScroll from "../../../hooks/useContainerScroll";
import { Medium, SmallOnly } from "../../ScreenSize";
import { usePublicViewEntityContext } from "../../../context/PublicViewEntityProvider";
import { usePublicViewTemplateDataContext } from "../../../context/PublicViewTemplateDataProvider";

const CATEGORY_IMAGE_CONTAINER_STYLE = {
  margin: 0,
  padding: "16px 0",
  minHeight: "100vh",
};

const CATEGORY_IMAGE_ITEM_STYLE = {
  height: "800px",
  marginBottom: "16px",
  position: "relative",
  cursor: "pointer",
  zIndex: 1,
};

const CATEGORY_IMAGE_ITEM_TEXT_STYLE = {
  fontFamily: "Bebas Neue",
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#fff",
  zIndex: 99,
  textAlign: "center",
  overflow: "hidden",
};

const CATEGORY_IMAGE_ITEM_IMAGE_STYLE = {
  width: "100%",
  height: "100%",
  objectFit: "cover",
  objectPosition: "center",
  transition: "filter 0.2s linear",
};

const ARROW_ICON_STYLE = {
  margin: 4,
  fontSize: "3em",
  position: "absolute",
  zIndex: 4,
  width: `calc(100vw - ${TEMPLATE_SIDEBAR_WIDTH}px - 20px)`,
  bottom: 24,
  left: "50%",
  transform: "translateX(-50%)",
  cursor: "pointer",
};

const CategoryImageItem = ({
  categoryName,
  categoryImage,
  handleClick,
  index,
  isMobile,
  categorId,
  showCategoryTitle = true,
}) => {
  const [hover, setHover] = useState(false);

  return (
    <Csku
      as={Col}
      xs={12}
      sm={6}
      forceStyles
      style={{
        ...CATEGORY_IMAGE_ITEM_STYLE,
        ...(isMobile
          ? { height: "48vh" }
          : {
              paddingLeft: index % 2 === 0 ? "0" : "8px",
              paddingRight: index % 2 === 0 ? "8px" : "0",
            }),
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => handleClick(categorId)}
    >
      <img
        src={categoryImage}
        alt={categoryName}
        style={{
          ...CATEGORY_IMAGE_ITEM_IMAGE_STYLE,
          filter: hover ? "brightness(0.5)" : "brightness(0.8)",
        }}
      />
      {showCategoryTitle && (
        <Csku
          as="p"
          forceStyles
          style={CATEGORY_IMAGE_ITEM_TEXT_STYLE}
          sx={{
            xs: {
              fontSize: "64px",
              lineHeight: "77px",
            },
            sm: {
              fontSize: "60px",
              lineHeight: "48px",
            },
          }}
        >
          {categoryName}
        </Csku>
      )}
    </Csku>
  );
};

const CategoryImagesContainer = ({
  categories,
  handleClick,
  isMobile = false,
  showCategoryTitle = true,
}) => {
  return (
    <Csku forceStyles as={Row} style={CATEGORY_IMAGE_CONTAINER_STYLE}>
      {categories.map((category, index) => (
        <CategoryImageItem
          key={category.item_id}
          categorId={category.item_id}
          categoryImage={category.categoryImages[1]}
          categoryName={category.item_name}
          index={index}
          handleClick={handleClick}
          isMobile={isMobile}
          showCategoryTitle={showCategoryTitle}
        />
      ))}
    </Csku>
  );
};

function LeftNavHeroBannerTemplate(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    entityOrder: gallery,
    baseEntityUrl,
    entityBuyInventory,
    entityType,
  } = usePublicViewEntityContext();
  const {
    templateColor: template_color,
    template_data,
    getPosterCategories,
    getPublicViewTemplateDefaultProperty,
  } = usePublicViewTemplateDataContext();

  const filter = useSelector((state) => state.gallery.filter);

  const showCategoryTitle = useMemo(
    () =>
      get(template_data, ["categorytitle", "value"], "1") ===
      "1",
    [template_data]
  );

  const categories = useMemo(() => {
    const items = get(gallery, ["items"]) || [];
    return getPosterCategories(items.filter(i => 1 !== +i.hidden), template_data).filter(
      (c) => c.item_id !== "ALL_PRODUCTS"
    );
  }, [gallery, template_data, getPosterCategories]);

  const homeBg = useMemo(
    () =>
      get(
        template_data,
        ["background", "value"],
        getPublicViewTemplateDefaultProperty(
          "background",
          template_data.public_view_template_properties
        )
      ),
    [template_data, getPublicViewTemplateDefaultProperty]
  );

  const ref = useRef(null);
  const { scrollDown, isAtTop } = useContainerScroll(ref);

  const loadOrder = useCallback(
    (order_id) => dispatch(createLoadOrder(order_id)),
    [dispatch]
  );

  const handleClickCategory = (category) => {
    navigate(
      baseEntityUrl + (!!entityBuyInventory ? "?buy_inventory=true" : "")
    );
    dispatch(setFilter(category));
  };

  useEffect(() => {
    if (!gallery?.loaded && gallery?.order_id) {
      loadOrder(gallery?.order_id);
    }
  }, [gallery?.loaded, gallery?.order_id, loadOrder]);

  return (
    <div id="home" style={{ background: "white" }}>
      <Csku
        forceStyles
        sx={{ xs: { width: "100%" }, sm: { width: TEMPLATE_SIDEBAR_WIDTH } }}
      >
        <Csku
          as={Row}
          sx={{
            xs: { width: "100%" },
            sm: { width: TEMPLATE_SIDEBAR_WIDTH, position: "fixed" },
          }}
        >
          <Sidebar transparentMobileNav={isAtTop()} />
        </Csku>
      </Csku>

      <Csku
        forceStyles
        sx={{
          xs: { width: "100%" },
          sm: {
            width: `calc(100% - ${TEMPLATE_SIDEBAR_WIDTH}px)`,
            marginLeft: TEMPLATE_SIDEBAR_WIDTH,
          },
        }}
      >
        {filter === "" && (
          <div ref={ref} style={{ height: "100vh", overflowY: "scroll" }}>
            <div
              style={{
                height: "100vh",
                background: `url('${homeBg}')`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                position: "relative",
              }}
            >
              <DoubleArrowIcon
                direction="down"
                color={template_color}
                onClick={() => scrollDown()}
                style={ARROW_ICON_STYLE}
              />
            </div>
            <Medium>
              <CategoryImagesContainer
                categories={categories}
                handleClick={handleClickCategory}
                showCategoryTitle={showCategoryTitle}
              />
            </Medium>
            <SmallOnly>
              <CategoryImagesContainer
                categories={categories}
                handleClick={handleClickCategory}
                showCategoryTitle={showCategoryTitle}
                isMobile
              />
            </SmallOnly>
            <Footer
              centerAligned
              withTopBorder={false}
              overrideFontStyle
              style={entityType === 'ORDER' ? { position: 'unset' } : {}}
            />
          </div>
        )}
      </Csku>
    </div>
  );
}

export default LeftNavHeroBannerTemplate;
